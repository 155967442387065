import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizTeenager1', 'quizTeenager2', 'quizTeenager3', 'quizTeenager4', 'quizTeenager5', 'quizTeenager6', 'textRevealPandemicScreenTime'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const TextReveal = makeShortcode("TextReveal");
const PandemicInformation = makeShortcode("PandemicInformation");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "activity"
    }}>{`Activity`}</h1>
    <p>{`Is your teen’s screen use healthy? Answer these questions to consider if your teen needs to change their screen use habits.`}</p>
    <h3 {...{
      "id": "is-your-teenager"
    }}>{`Is your teenager:`}</h3>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager1" singleAnswer={true} question="Getting enough sleep?" answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager2" singleAnswer={true} question="Physically active each day?" answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager3" singleAnswer={true} question="Spending time with family and friends, both online and offline?" answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager4" singleAnswer={true} question="Doing fun activities or hobbies, both online and offline?" answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager5" question="Enjoying or learning from what they do online?" singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeenager6" question="Using good-quality and helpful content when they are online?" singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <p>{`If you answered “No” to any of these questions, your teenager may benefit from reducing their screen time. See the next page for tips on setting boundaries about screen time.
You can also find links to more information in the `}<GoTo to="/appendix" mdxType="GoTo">{`Appendix`}</GoTo>{`.`}</p>
    <TextReveal id="textRevealPandemicScreenTime" header={<PandemicInformation mdxType="PandemicInformation">What about increased screen time during the pandemic? (click to reveal)</PandemicInformation>} mdxType="TextReveal">
  <p>
    It’s unavoidable that your teen’s screen time will increase at times during the pandemic. In fact, this will likely
    be necessary for your teen to stay connected with their peers and continue with activities that are important to
    them.
  </p>
  <p>
    If your teen is spending more time online, try to monitor how this is affecting them. Most importantly, ensure it’s
    not causing them any harm &mdash; for example, are they seeing material that’s making them more anxious? What about
    cyberbullying, or risk of online sexual exploitation? Now more than ever, it’s important to check in with your teen
    about how they are spending their time online.
  </p>
  <p>
    <b>Don’t forget screen-free time.</b> Even if local restrictions limit time spent out of the house, it’s important
    to spend time each day away from our devices. Your teen might need your encouragement &mdash; brainstorm some
    options with them (e.g. crafts, reading, playing with a pet).
  </p>
    </TextReveal>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      